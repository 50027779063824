import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { useMeta } from 'api';
// import { FullscreenLoader } from 'components';
import HomePage from 'pages/index';
import ProjectViewPage from 'pages/[id]';

import styles from './App.module.scss';

function App() {
  const { data: meta } = useMeta();

  // if (isLoading) return <FullscreenLoader />;

  const logoAppleTouchIcon = meta?.logoAppleTouchIcon || '/apple-touch-icon.png?v=reeeal';
  const logoIco = meta?.logoIco || '/favicon.ico?v=reeeal';
  const logoSvg = meta?.logoSvg || '/favicon.svg?v=reeeal';
  const logoPng = (size: string) => (meta?.logoSvg ? '' : `/favicon-${size}.png?v=reeeal`);
  const webManifest = meta?.webManifest || '/site.webmanifest?v=reeeal';
  const maskIcon = meta?.logoSvg ? '' : '/safari-pinned-tab.svg?v=reeeal';
  const browserConfig = meta?.logoSvg ? '' : '/browserconfig.xml';
  const title = meta?.title || 'reeeal';

  return (
    <div className={styles.root}>
      <Helmet titleTemplate={`%s / ${title}`}>
        <title>WebAR</title>
        <meta name='description' content='WebAR projects on the palm of your hand' />

        {logoPng && <link rel='icon' sizes='32x32' href={logoPng('32x32')} type='image/png' />}
        {logoPng && <link rel='icon' sizes='16x16' href={logoPng('16x16')} type='image/png' />}
        <link rel='icon' sizes='any' href={logoSvg} type='image/svg+xml' />
        <link rel='icon' sizes='16x16' href={logoIco} type='image/vnd.microsoft.icon' />
        <link rel='apple-touch-icon' sizes='180x180' href={logoAppleTouchIcon} />
        {/*
          manifest.json provides metadata used when your web app is installed on a
          user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/
        */}
        <link rel='manifest' href={webManifest} />
        <link rel='shortcut icon' href={logoIco} />
        {maskIcon && <link rel='mask-icon' href={maskIcon} color='#5bbad5' />}
        {browserConfig && <meta name='msapplication-config' content={browserConfig} />}
      </Helmet>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path=':id' element={<ProjectViewPage />} />
      </Routes>
    </div>
  );
}

export default App;
