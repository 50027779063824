import { useQuery } from '@tanstack/react-query';
import http from 'api/config';
import { Meta } from 'api/types';
import { AxiosResponse } from 'axios';

export async function getMeta() {
  const response: AxiosResponse<Meta> = await http.get(`webar/meta`);

  return response.data;
}

export default function useMeta() {
  return useQuery(['webar/meta'], getMeta);
}
