import axios from 'axios';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient();

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/' : process.env.REACT_APP_API_ORIGIN,
  timeout: 10000,
  headers: {
    'X-Domain': window.location.hostname,
  },
});

export default http;
