import { ProjectView } from 'components';
import { ProjectContextProvider } from 'contexts';

export default function ProjectViewPage() {
  return (
    <ProjectContextProvider>
      <ProjectView />
    </ProjectContextProvider>
  );
}
