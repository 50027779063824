import { useProject } from 'api/hooks';
import { ModelViewer } from 'components';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useRef } from 'react';
import querystring from 'query-string';
import styles from './ProjectView.module.scss';

// const bannerProps = 'callToAction=Claim%20NFT&checkoutTitle=Gift&checkoutSubtitle=Claim%20your%20free%20NFT';

export default function ProjectView() {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { id: projectUUID } = useParams();

  const { data } = useProject(projectUUID!);

  // const startPlay = () => {
  //   const audio = audioRef.current;

  //   if (!audio) return;

  //   audio.play();
  // };

  const stopPlay = () => {
    const audio = audioRef.current;

    if (!audio) return;

    audio.pause();
    audio.currentTime = 0;
  };

  const handleQuickLookButtonTap = useCallback(() => {
    if (!data?.projectAction) return;

    const { redirectUrl } = data.projectAction;

    if (!redirectUrl) return;

    stopPlay();
    window.location.href = redirectUrl;
  }, [data]);

  const src = useMemo(() => {
    if (!data?.androidSource) return '';

    if (!data?.projectAction) return data.androidSource;

    const { redirectUrl, title } = data.projectAction;

    return querystring.stringifyUrl({
      url: data.androidSource,
      query: { link: redirectUrl, title },
    });
  }, [data]);

  const iosSrc = useMemo(() => {
    if (!data?.iosSource) return '';

    if (!data?.projectAction) return data.iosSource;

    const { buttonText, title, subtitle } = data.projectAction;

    const query = querystring.stringify({
      callToAction: buttonText,
      checkoutTitle: title,
      checkoutSubtitle: subtitle,
    });

    return query ? `${data.iosSource}#${query}` : data.iosSource;
  }, [data]);

  if (!data) return null;

  const { bgSound: audioSrc } = data;

  return (
    <div className={styles.root}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef}>
        <source src={audioSrc} type='audio/mpeg' />
      </audio>
      <ModelViewer
        src={src}
        iosSrc={iosSrc}
        // onAr={startPlay}
        onQuickLookButtonTapped={handleQuickLookButtonTap}
        // src='https://themishka.s3.eu-west-2.amazonaws.com/04e800fb-0137-4bc7-aa27-abc08e6203c9.glb?title=Claim%20your%20free%20NFT&link=https://nft-love.com'
        // iosSrc={`https://themishka.s3.eu-west-2.amazonaws.com/09cdb6b7-8dce-4d8a-95e0-a011ccd3d81d.usdz#${iosQueryString}`}
      />
    </div>
  );
}
