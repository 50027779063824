import { useQuery } from '@tanstack/react-query';
import http from 'api/config';
import { FetcherParams, ProjectReqParams, ProjectResponse } from 'api/types';
import { AxiosResponse } from 'axios';

export async function getProject({ queryKey }: FetcherParams<ProjectReqParams>) {
  const [_key, { uuid }] = queryKey;
  const response: AxiosResponse<ProjectResponse> = await http.get(`webar/${uuid}`);

  return response.data;
}

export default function useProject(uuid: string) {
  return useQuery(['webar/index', { uuid }], getProject);
}
