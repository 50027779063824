import {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

export interface ProjectState {
  arWasActivated: boolean
}

export interface ProjectContextState {
  state: ProjectState
  setState: Dispatch<SetStateAction<ProjectState>>
}

export const projectContextInitialValue: ProjectContextState = {
  state: {
    arWasActivated: false,
  },
  setState: () => {},
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ProjectContext = createContext<ProjectContextState>(projectContextInitialValue);

export const useProjectState = () => useContext(ProjectContext);

export default function ProjectContextProvider({ children }: PropsWithChildren) {
  const [state, setState] = useState<ProjectState>(() => projectContextInitialValue.state);

  const value = useMemo(() => ({
    state,
    setState,
  }), [state]);

  return (
    <ProjectContext.Provider value={value}>
      {children}
    </ProjectContext.Provider>
  );
}
