import { ModelViewerElement } from '@google/model-viewer';
import { MouseEvent, MouseEventHandler, useLayoutEffect, useRef } from 'react';
import styles from './ModelViewer.module.scss';

export interface ModelViewerProps {
  src: string;
  iosSrc: string;
  onAr?: () => void;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onQuickLookButtonTapped?: () => void;
}

export default function ModelViewerComp({ src, iosSrc, onAr, onClick, onQuickLookButtonTapped }: ModelViewerProps) {
  const modelViewerRef = useRef<ModelViewerElement | null>(null);
  const savedOnQuickLookButtonTappedCb = useRef<(() => void) | null>(null);

  async function activateAR() {
    const modelViewer = modelViewerRef.current;

    if (modelViewer?.canActivateAR) {
      try {
        await modelViewer.activateAR();
        onAr?.();
      } catch (err) {
        /* empty */
      }
    }
  }

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    activateAR();
    onClick?.(e);
  }

  useLayoutEffect(() => {
    const modelViewer = modelViewerRef.current;
    const eventName = 'quick-look-button-tapped';

    if (modelViewer && savedOnQuickLookButtonTappedCb.current) {
      modelViewer.removeEventListener(eventName, savedOnQuickLookButtonTappedCb.current);
    }

    if (modelViewer && onQuickLookButtonTapped) {
      modelViewer.addEventListener(eventName, onQuickLookButtonTapped);
      savedOnQuickLookButtonTappedCb.current = onQuickLookButtonTapped;
    }

    return () => {
      if (modelViewer && onQuickLookButtonTapped) {
        modelViewer.removeEventListener(eventName, onQuickLookButtonTapped);
      }
    };
  }, [onQuickLookButtonTapped]);

  return (
    // @ts-ignore
    <model-viewer
      ref={modelViewerRef}
      class={styles.root}
      src={src}
      ios-src={iosSrc}
      ar
      ar-modes='quick-look scene-viewer webxr'
      ar-scale='auto'
      camera-controls
    >
      <button className={styles.button} slot='ar-button' type='button' onClick={handleClick}>
        Open AR
      </button>
    </model-viewer>
  );
}
